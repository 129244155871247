import { Link } from "gatsby"
import React, { useState } from "react"
import { css } from "emotion"
import { Collapse } from "react-collapse"

import logoImage from "../images/2022-02/alex-riley-logo-round.png"

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const openMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <header
      style={{
        background: `white`,
      }}
    >
      <div className={mobileHeader}>        
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link
              to="/"
              style={{
                color: `black`,
                textDecoration: `none`,
              }}
            >            
              <div
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50%",
                  backgroundImage: `url(${logoImage})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",                   
                }}                
              />

            </Link>
            <button
              className={collapseButton}
              onClick={openMenu}
              style={{ backgroundColor: isMenuOpen ? "#d3d3d3" : "#f4f4f4" }}
            >
              <svg
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 352 286"
                className={svg}
                fill="white"
              >
                <defs />
                <title>Untitled-1</title>
                <line class="cls-1" x1="23" y1="23" x2="329" y2="23" />
                <line class="cls-1" x1="23" y1="143" x2="329" y2="143" />
                <line class="cls-1" x1="23" y1="263" x2="329" y2="263" />
              </svg>
            </button>
          </div>

          <Collapse isOpened={isMenuOpen}>
            <div className={linkContainer}>
              <Link to="/" className={headerItem}>
                Home
              </Link>
              <Link to="/about" className={headerItem}>
                About
              </Link>
              <Link to="/issues" className={headerItem}>
                Issues
              </Link>
              {/* <Link to="/endorsements" className={headerItem}>
                Endorsements
              </Link> */}
              <Link to="/up-to-date" className={headerItem}>
                Take Action
              </Link>
              <Link to="/donate" className={headerItem}>
                Donate
              </Link>
            </div>
          </Collapse>
        </div>
      </div>
      <div className={desktopHeader}>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
            <div
              style={{
                height: "150px",
                width: "150px",
                borderRadius: "50%",
                backgroundImage: `url(${logoImage})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",              
              }}                        
            />
        </Link>
        <Link to="/" className={headerItem}>
          Home
        </Link>
        <Link to="/about" className={headerItem}>
          About
        </Link>
        <Link to="/issues" className={headerItem}>
          Issues
        </Link>
        {/* <Link to="/endorsements" className={headerItem}>
          Endorsements
        </Link> */}
        <Link to="/up-to-date" className={headerItem}>
          Take Action
        </Link>
        <Link to="/donate" className={headerItem}>
          Donate
        </Link>
      </div>
    </header>
  )
}

export default Header

const headerItem = css`
  && {
    color: black;
    text-decoration: none;
    border-bottom: 2px solid transparent;
  }

  &:hover {
    border-bottom: 2px solid #9c2228;
  }
`
const desktopHeader = css`
  margin-left: 10%;
  margin-right: 10%;
  max-width: 1200;
  padding: 1.45rem 1.0875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media only screen and (max-width: 900px) {
    margin-left: 2%;
    margin-right: 2%;
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }
`

const mobileHeader = css`
  margin: 0 auto;
  max-width: 1200;
  padding: 1.45rem 1.0875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 700px) {
    display: none;
  }
`
const collapseButton = css`
  border: 2px solid #999999;
  width: 50px;
  border-radius: 5px;

  transition-property: background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
`
const svg = css`
  padding-top: 5px;
  fill: none;
  stroke: #494949;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 46px;
`

const linkContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & > * {
    margin: 10px;
  }
`
